import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca policy authority ssh`}</strong>{` -- manage SSH certificate issuance policies`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca policy ssh <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca policy ssh`}</strong>{` command group provides facilities for managing SSH certificate issuance policies.`}</p>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "host/"
              }}>{`host`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage SSH host certificate issuance policies`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "user/"
              }}>{`user`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`manage SSH user certificate issuance policies`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      